body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: "center";
  justify-content: center;
  height: 100vh;
}

.page-container {
  padding: 16px;
}
